import React, { useState } from "react";
import "./VotreFormulaire.css"; // Assurez-vous que le chemin est correct pour votre fichier CSS
import Contact from "./Contact";
import { Helmet } from "react-helmet";

function RepriseDepotsVenteContent() {
  const [step, setStep] = useState(1);

  // Partie 1 - Modèle et mécanique
  const [marque, setMarque] = useState("");
  const [modele, setModele] = useState("");
  const [boiteVitesse, setBoiteVitesse] = useState("");
  const [carburant, setCarburant] = useState("");
  const [kilometrage, setKilometrage] = useState("");
  const [couleur, setCouleur] = useState("");
  const [options, setOptions] = useState("");
  const [puissance, setPuissance] = useState("");
  const [year, setYear] = useState("");
  const [immat, setImmat] = useState("");
  const [nom, setNom] = useState("");
  const [numeroTelephone, setNumeroTelephone] = useState("");

  const [nomError, setNomError] = useState(true);
  const [numeroTelephoneError, setNumeroTelephoneError] = useState(true);

  const validateForm = () => {
    let isValid = true;
    if (!nom) {
      setNomError(true);
      isValid = false;
    }
    if (!numeroTelephone) {
      setNumeroTelephoneError(true);
      isValid = false;
    }
    return isValid;
  };

  const nextStep = () => {
    window.scrollTo(0, 0);
    setStep(step + 1);
  };

  const prevStep = () => {
    window.scrollTo(0, 0);
    setStep(step - 1);
  };

  const sendForm = async () => {
    if (validateForm()) {
      const data = {
        formulaire: "REPRISE/DEPOT VENTE",
        marque: marque,
        modele: modele,
        boiteVitesse: boiteVitesse,
        carburant: carburant,
        kilometrage: kilometrage,
        couleur: couleur,
        options: options,
        puissance: puissance,
        nom: nom,
        numeroTelephone: numeroTelephone,
        year: year,
        immat: immat,
      };

      try {
        await fetch("https://flipmycar.fr/api/sendEmail", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
          credentials: "same-origin",
        });
      } catch (error) {
        console.error("Error:", error);
      }
      window.scrollTo(0, 0);
      setStep(3);
    }
    return null;
  };

  return (
    <div>
      <Helmet>
        <title>Reprise & dêpot vente - FLIP MY CAR</title>
        <meta
          name="description"
          content="Remplissez notre formulaire de contact pour obtenir une estimation de reprise ou pour mettre votre voiture en dépôt-vente. Notre équipe vous contactera rapidement pour vous aider dans votre démarche."
        />
        <meta
          name="keywords"
          content="formulaire de contact, estimation reprise, dépôt vente voiture, contact équipe FLIP MY CAR, vendre voiture, formulaire reprise véhicule"
        />
      </Helmet>

      <div className="Reprise">
        <h1 className="RepriseTitle">Formulaire de contact </h1>
        <div className="formulaire-container">
          <here>
            {/* Partie 1 */}
            {step === 1 && (
              <div className="form-step">
                <h2>Étape 1 - Configuration de voiture</h2>
                <br></br>
                <label className="labelmenuderoulant">
                  <t className="label">Marque :</t>
                  <input
                    type="text"
                    value={marque}
                    onChange={(e) => setMarque(e.target.value)}
                  />
                </label>
                <label className="labelmenuderoulant">
                  <t className="label"> Modèle :</t>
                  <input
                    type="text"
                    value={modele}
                    onChange={(e) => setModele(e.target.value)}
                  />
                </label>
                <label className="labelmenuderoulant">
                  <t className="label">Transmission :</t>
                  <select
                    value={boiteVitesse}
                    onChange={(e) => setBoiteVitesse(e.target.value)}
                    className="menudroulant"
                  >
                    <option value="">Sélectionnez une boîte de vitesse</option>
                    <option value="Manuelle">Manuelle</option>
                    <option value="Automatique">Automatique</option>
                    {/* Ajoutez d'autres options au besoin */}
                  </select>
                </label>
                <label className="labelmenuderoulant">
                  <t className="label">Carburant :</t>
                  <select
                    value={carburant}
                    onChange={(e) => setCarburant(e.target.value)}
                    className="menudroulant"
                  >
                    <option value="">Sélectionnez un carburant</option>
                    <option value="Essence">Essence</option>
                    <option value="Hybride">Hybride</option>
                    <option value="Diesel">Diesel</option>
                    <option value="Electrique">Electrique</option>
                    {/* Ajoutez d'autres options au besoin */}
                  </select>
                </label>
                <label className="labelmenuderoulant">
                  <t className="label">Puissance en cv :</t>
                  <input
                    type="text"
                    value={puissance}
                    onChange={(e) => setPuissance(e.target.value)}
                  />
                </label>
                <label className="labelmenuderoulant">
                  <t className="label"> Kilométrage actuel : </t>
                  <input
                    type="text"
                    value={kilometrage}
                    onChange={(e) => setKilometrage(e.target.value)}
                  />
                  {/* Ajoutez d'autres options au besoin */}
                </label>
                <label className="labelmenuderoulant">
                  <t className="label">Année de mise en circulation :</t>
                  <input
                    type="text"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                  />
                </label>
                <label className="labelmenuderoulant">
                  <t className="label"> Couleur exterieure :</t>
                  <input
                    type="text"
                    value={couleur}
                    onChange={(e) => setCouleur(e.target.value)}
                  />
                </label>
                <label className="labelmenuderoulant">
                  <t className="label">Options :</t>
                  <input
                    type="text"
                    value={options}
                    onChange={(e) => setOptions(e.target.value)}
                  />
                </label>
                <label className="labelmenuderoulant">
                  <t className="label">Immatriculation :</t>
                  <input
                    type="text"
                    value={immat}
                    onChange={(e) => setImmat(e.target.value)}
                  />
                </label>
                <p style={{ color: "grey" }}>
                  Tous les champs ne sont pas obligatoires
                </p>
                <button type="button" onClick={nextStep}>
                  Suivant
                </button>
              </div>
            )}
            {/* Partie 2 */}
            {step === 2 && (
              <div className="form-step">
                <h2>Étape 2 - Nous vous rappelons</h2>
                <br></br>
                <label className="labelmenuderoulant">
                  <t className="label">Nom :</t>
                  <input
                    type="text"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    required
                  />
                </label>
                <label className="labelmenuderoulant">
                  <t className="label">Numéro de téléphone :</t>
                  <input
                    type="text"
                    value={numeroTelephone}
                    onChange={(e) => setNumeroTelephone(e.target.value)}
                    required // Add the required attribute
                  />
                </label>
                <div className="selectpage">
                  <button type="button" onClick={prevStep}>
                    Précédents
                  </button>
                  <button onClick={() => [sendForm()]}>Envoyer</button>
                </div>
              </div>
            )}
            {/* Partie 4 */}
            {step === 3 && (
              <div className="form-step">
                <h2>Confirmation</h2>
                <p>Votre formulaire a été transmis à notre équipe.</p>
              </div>
            )}
          </here>
        </div>
        <Contact />
        <br />
        <div className="bannieredubas">
          <img
            src={process.env.PUBLIC_URL + "/IMG_6557.jpeg"}
            alt="bannieredubas"
            className="bannieredubas"
          />
        </div>
      </div>
    </div>
  );
}

export default RepriseDepotsVenteContent;
