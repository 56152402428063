// NosVehiculesContent.js
import React, { useState, useEffect } from "react";
import AnnonceCard from "./AnnonceCard"; // Make sure the path is correct
import "./NosVehiculesContent.css";
import { getApps, initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs,
  query, where
 } from "firebase/firestore";
import Modal from "react-modal";
import VehicleDetails from "./VehicleDetails";

const firebaseConfig = {
  apiKey: "AIzaSyDdfjZnSjhYo6FbRnXE9b_dub51EHROQrk",
  authDomain: "flipmycar-f7d3d.firebaseapp.com",
  projectId: "flipmycar-f7d3d",
  storageBucket: "flipmycar-f7d3d.appspot.com",
  messagingSenderId: "313272014258",
  appId: "1:313272014258:web:0b5b829efc9423e1df40ab",
  measurementId: "G-RQ3GXS89XV",
};

function NosVehiculesContent() {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [maxKilometres, setMaxKilometres] = useState(200000);
  const [maxPrix, setMaxPrix] = useState(100000);
  const [minAnnee, setMinAnnee] = useState(1900);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Véhicules filtrés en fonction des filtres
  const filteredVehicles = vehicles.filter((vehicle) => {
    const date = vehicle.MiseEnCiruclation.toDate();
    // Extract the year
    const year = date.getFullYear();
    console.log(year);
    return (
      vehicle.kilometrage <= maxKilometres &&
      vehicle.prix <= maxPrix &&
      year >= minAnnee
    );
  });

  const handleVehicleClick = (vehicle) => {
    setSelectedVehicle(vehicle);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedVehicle(null);
    setModalIsOpen(false);
  };

  useEffect(() => {
    // Mettez à jour les véhicules filtrés à chaque changement de filtre
    // Vous pouvez également utiliser ces valeurs pour effectuer une nouvelle requête API
    console.log("Filtres mis à jour :", { maxKilometres, maxPrix, minAnnee });
  }, [maxKilometres, maxPrix, minAnnee]);

  useEffect(() => {
    let isMounted = true;

    async function getData() {
      // Initialize Firebase
      if (!getApps().length) {
        initializeApp(firebaseConfig);
      }
      const db = getFirestore();
      const vdispoCollection = collection(db, "VDISPO");
      try {
        const q = query(vdispoCollection, where("vendu", "==", false));
        const vdispoSnapshot = await getDocs(q);
        let vdispoList = vdispoSnapshot.docs
          ?.map((doc) => ({ id: doc.id, images: doc.images, ...doc.data() }))
          .filter(Boolean);
        // short vehicule list by createdAt inverse order
        vdispoList = vdispoList.sort((a, b) => b.createdAt - a.createdAt);
        if (isMounted) {
          setVehicles(vdispoList);
          setLoading(false);
          console.log("Véhicules récupérés:", { vehicles });
        }
      } catch (error) {
        console.error("Error getting documents:", error);
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    getData();

    return () => {
      isMounted = false;
    };
  }, []);

  // Show a loading message while data is being fetched
  if (loading) {
    return <h2>Chargement en cours...</h2>;
  }

  return (
    <div>
      <div className="ColTitleVDispo">
        <h1 className="titleVDispo">Nos véhicules disponibles</h1>
        <img
          src={process.env.PUBLIC_URL + "/megane4.png"}
          className="Megane4Title"
          alt='Megane4'
        />
      </div>
      {/* <div className="filters">
        <label>
          Kilométrage maximum:
          <select
            value={maxKilometres}
            onChange={(e) => setMaxKilometres(Number(e.target.value))}
            className="select"
          >
            <option value={10000}>10 000 km</option>
            <option value={25000}>25 000 km</option>
            <option value={35000}>35 000 km</option>
            <option value={50000}>50 000 km</option>
            <option value={75000}>75 000 km</option>
            <option value={100000}>100 000 km</option>
            {/* Ajoutez d'autres options au besoin */}
          {/* </select>
        </label>

        <label>
          Prix maximum:
          <select
            value={maxPrix}
            onChange={(e) => setMaxPrix(Number(e.target.value))}
            className="select"
          >
            <option value={20000}>20 000 €</option>
            <option value={30000}>25 000 €</option>
            <option value={30000}>30 000 €</option>
            <option value={40000}>40 000 €</option>
            <option value={50000}>50 000 €</option>
            <option value={75000}>75 000 €</option>
            <option value={100000}>100 000 €</option>
            {/* Ajoutez d'autres options au besoin */}
          {/* </select>
        </label>

        <label>
          Année minimum:
          <select
            value={minAnnee}
            onChange={(e) => setMinAnnee(Number(e.target.value))}
            className="select"
          >
            <option value={2014}>2014</option>
            <option value={2015}>2015</option>
            <option value={2016}>2016</option>
            <option value={2017}>2017</option>
            <option value={2018}>2018</option>
            <option value={2019}>2019</option>
            <option value={2020}>2020</option>
            <option value={2021}>2021</option>
            <option value={2022}>2022</option>

            {/* Ajoutez d'autres options au besoin */}
          {/* </select>
        </label>
      </div> */}
      <div>
        {filteredVehicles.length === 0 ? (
          <h1 className="NoVehicleFound">
            Aucun véhicule trouvé avec les filtres actuels.
          </h1>
        ) : (
          <div className="vehicle-list-container">
            <div className="vehicle-list">
              {filteredVehicles.map((vehicle, index) => (
                <AnnonceCard
                  key={index}
                  vehicle={vehicle}
                  onVehicleClick={() => handleVehicleClick(vehicle)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Vehicle Details"
        style={{
          overlay: {
            backgroundColor: "#000000",
          },
          content: {
            backgroundColor: "#000000",
            border: "none",
            top: "0%",
            left: "0%",
            right: "auto",
            bottom: "auto",
            width: "100%",
            height: "100%",
            padding: "0px",
          },
        }}
      >
        {selectedVehicle && (
          <VehicleDetails vehicle={selectedVehicle} onClose={closeModal} />
        )}
      </Modal>
    </div>
  );
}

export default NosVehiculesContent;
