import React, { useState, useEffect } from "react";
import "./App.css";
import Section from "./Section";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from '@vercel/speed-insights/react';
const window = global;

function App() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const [section, setSection] = useState("Accueil");

  const changeSection = (newSection) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setSection(newSection);
  };

  useEffect(() => {
    const hash = window.location.href;

    if (hash.includes("reprise-depotvente")) {
      setSection("Reprise/Dépôts vente");
    }
    if (hash.includes("recherche-personnalisee")) {
      setSection("Recherches personnalisées");
    }
    if (hash.includes("nos-vehicules")) {
      setSection("Nos véhicules");
    }
    if (hash.includes("politique-confidentialite")) {
      setSection("Politique de confidentialité");
    }
  }, []);

  if (isMenuOpen) {
    document.body.style.overflow = "hidden";
  }

  if (isMenuOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }

  return (
    <div className="App">
      <header className="Header">
        <div className="Menu" onClick={toggleMenu}>
          {/* Remplacez 'hamburger.svg' par le chemin correct de votre fichier hamburger */}
          <img src={process.env.PUBLIC_URL + "/hamburger.svg"} alt="Menu" />
        </div>
        <div className="Logo">
          <a href="#" onClick={() => changeSection("Accueil")}>
            <img src={process.env.PUBLIC_URL + "/logo2.png"} alt="Logo" />
          </a>
        </div>
        <div className="Contact">
          {/* Remplacez 'instagram.svg' par le chemin correct de votre logo Instagram */}
          <a href="https://www.instagram.com/flipmycar.fr" target="_blank">
            <img
              src={process.env.PUBLIC_URL + "/instagram.svg"}
              alt="Instagram"
            />
          </a>
        </div>
      </header>

      <div
        className={`MenuOverlay ${isMenuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <nav className="MobileMenu">
          <ul>
            <li>
              <a href="#" onClick={() => changeSection("Accueil")}>
                Accueil
              </a>
            </li>
            <li>
              <a href="#" onClick={() => changeSection("Nos véhicules")}>
                Véhicules disponibles
              </a>
            </li>
            <li>
              <a href="#" onClick={() => changeSection("Reprise/Dépôts vente")}>
                Reprise et dépôt vente
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={() => changeSection("Recherches personnalisées")}
              >
                Recherche personnalisée
              </a>
            </li>
            <li>
              <a href="#" onClick={() => changeSection("Véhicules vendus")}>
                Véhicules vendus
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <main className="Main">
        <Section section={section} changeSection={changeSection} />
      </main>
      <Analytics></Analytics>
      <SpeedInsights />
      <footer className="Footer">
        <div className="ColFooter">
          <div>
            <h3>Adresse</h3>
            <p>Saint-Germain-en-Laye</p>
            <p>78100</p>
          </div>
          <div>
            <h3>Téléphone</h3>
            <p>06 82 75 46 29</p>
            <p>Également par SMS</p>
          </div>
          <div>
            <h3>Horaires</h3>
            <p>Du lundi au samedi</p>
            <p>De 9h à 19h</p>
          </div>
          <div>
            <a href="#" onClick={() => changeSection("Accueil")}>
              {/* Remplacez 'logo.svg' par le chemin correct de votre logo */}
              <img
                src={process.env.PUBLIC_URL + "/logo2.png"}
                alt="Logo"
                className="LogoFooter"
              />
            </a>
          </div>
        </div>
        <br />
        <div className="FooterSocial">
          {/* Remplacez 'instagram.svg' par le chemin correct de votre logo Instagram */}
          <a href="https://www.instagram.com/flipmycar.fr" target="_blank">
            <img
              src={process.env.PUBLIC_URL + "/instagram.svg"}
              alt="Instagram"
            />{" "}
          </a>
        </div>
        <div className="FooterText">
          <p>
            © 2024 Flipmycar.fr - Tous droits réservés - RCS de Paris 982 969
            198
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
