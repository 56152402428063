// NosVehiculesVendusContent.js
import React, { useState, useEffect } from "react";
import AnnonceCard from "./AnnonceCard"; // Make sure the path is correct
import "./NosVehiculesContent.css";
import { getApps, initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs,
  query, where
 } from "firebase/firestore";
import Modal from "react-modal";
import VehicleDetails from "./VehicleDetails";

const firebaseConfig = {
  apiKey: "AIzaSyDdfjZnSjhYo6FbRnXE9b_dub51EHROQrk",
  authDomain: "flipmycar-f7d3d.firebaseapp.com",
  projectId: "flipmycar-f7d3d",
  storageBucket: "flipmycar-f7d3d.appspot.com",
  messagingSenderId: "313272014258",
  appId: "1:313272014258:web:0b5b829efc9423e1df40ab",
  measurementId: "G-RQ3GXS89XV",
};

function NosVehiculesVendusContent() {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [maxKilometres, setMaxKilometres] = useState(100000);
  const [maxPrix, setMaxPrix] = useState(75000);
  const [minAnnee, setMinAnnee] = useState(2014);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Véhicules filtrés en fonction des filtres
  const filteredVehicles = vehicles.filter((vehicle) => {
    const date = vehicle.MiseEnCiruclation.toDate();
    // Extract the year
    const year = date.getFullYear();
    console.log(year);
    return (
      vehicle.kilometrage <= maxKilometres &&
      vehicle.prix <= maxPrix &&
      year >= minAnnee
    );
  });

  const handleVehicleClick = (vehicle) => {
    setSelectedVehicle(vehicle);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedVehicle(null);
    setModalIsOpen(false);
  };

  useEffect(() => {
    // Mettez à jour les véhicules filtrés à chaque changement de filtre
    // Vous pouvez également utiliser ces valeurs pour effectuer une nouvelle requête API
    console.log("Filtres mis à jour :", { maxKilometres, maxPrix, minAnnee });
  }, [maxKilometres, maxPrix, minAnnee]);

  useEffect(() => {
    let isMounted = true;

    async function getData() {
      // Initialize Firebase
      if (!getApps().length) {
        initializeApp(firebaseConfig);
      }
      const db = getFirestore();
      const vdispoCollection = collection(db, "VDISPO");
      try {
        const q = query(vdispoCollection, where("vendu", "==", true));
        const vdispoSnapshot = await getDocs(q);
        let vdispoList = vdispoSnapshot.docs
          ?.map((doc) => ({ id: doc.id, images: doc.images, ...doc.data() }))
          .filter(Boolean);
        // short vehicule list by createdAt inverse order
        vdispoList = vdispoList.sort((a, b) => b.createdAt - a.createdAt);
        if (isMounted) {
          setVehicles(vdispoList);
          setLoading(false);
          console.log("Véhicules récupérés:", { vehicles });
        }
      } catch (error) {
        console.error("Error getting documents:", error);
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    getData();

    return () => {
      isMounted = false;
    };
  }, []);

  // Show a loading message while data is being fetched
  if (loading) {
    return <h2>Chargement en cours...</h2>;
  }

  return (
    <div>
      <div className="ColTitleVDispo">
        <h1 className="titleVDispo">Nos véhicules vendus</h1>
        <img
          src={process.env.PUBLIC_URL + "/megane4.png"}
          className="Megane4Title"
          alt='Megane4'
        />
      </div>
      <div>
        {filteredVehicles.length === 0 ? (
          <h1 className="NoVehicleFound">
            Aucun véhicule trouvé avec les filtres actuels.
          </h1>
        ) : (
          <div className="vehicle-list-container">
            <div className="vehicle-list">
              {filteredVehicles.map((vehicle, index) => (
                <AnnonceCard
                  key={index}
                  vehicle={vehicle}
                  onVehicleClick={() => handleVehicleClick(vehicle)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Vehicle Details"
        style={{
          overlay: {
            backgroundColor: "#000000",
          },
          content: {
            backgroundColor: "#000000",
            border: "none",
            top: "0%",
            left: "0%",
            right: "auto",
            bottom: "auto",
            width: "100%",
            height: "100%",
            padding: "0px",
          },
        }}
      >
        {selectedVehicle && (
          <VehicleDetails vehicle={selectedVehicle} onClose={closeModal} />
        )}
      </Modal>
    </div>
  );
}

export default NosVehiculesVendusContent;
