// Section.js
import React from "react";
import NosVehiculesContent from "./NosVehiculesContent";
import AccueilContent from "./AccueilContent";
import RecherchesPersonnaliseesContent from "./RecherchesPersonnaliseesContent";
import RepriseDepotsVenteContent from "./RepriseDepotsVenteContent";
import NosVehiculesVendusContent from "./Vendu.js";
import PrivacyPolicy from "./PolitiqueConf.js";

const Section = ({ section, changeSection }) => {
  switch (section) {
    case "Accueil":
      return <AccueilContent changeSection={changeSection} />;
    case "Nos véhicules":
      return <NosVehiculesContent/>;
    case "Reprise/Dépôts vente":
      return <RepriseDepotsVenteContent />;
    case "Recherches personnalisées":
      return <RecherchesPersonnaliseesContent />;
    case "Véhicules vendus":
      return <NosVehiculesVendusContent />;
    case "Politique de confidentialité":
      return <PrivacyPolicy />;
    default:
      return null;
  }
};

export default Section;
