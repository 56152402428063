
import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <><div className="ColContacter">
          <div className="ColContacterDesc">
              <div>
                  <img src={process.env.PUBLIC_URL + '/S3.png'} className="S3" alt='Mercedes' />
              </div>
              <h1 className='titreContacter'>Contactez nous !</h1>
          </div>
      </div><div className="ColContacterData">

              <div className="Coordonnees">
                  <div className="OptionContact">
                      <div className="IconContactez">
                          <img src="phone-icon.svg" alt="Téléphone" className='Téléphone' />
                      </div>
                      <p>Téléphone : <a href="tel:+33682754629">06 82 75 46 29</a></p>
                  </div>
                  <div className="OptionContact">
                      <div className="IconContactez">
                          <img src="sms-icon.svg" alt="SMS" className='SMS' />
                      </div>
                      <p>SMS au <a href="sms:+33682754629">06 82 75 46 29</a></p>
                  </div>
              </div>
              <div className="Coordonnees">
                  <div className="OptionContact">
                      <div className="IconContactez">
                          <img src="mail-icon.svg" alt="Mail" className='Mail' />
                      </div>
                      <p>Email : <a href="mailto:contact@flipmycar.fr" className='Mail'>contact@flipmycar.fr</a></p>
                  </div>
                  <div className='OptionContact'>
                      <div className="IconContactez">
                          <img src={process.env.PUBLIC_URL + '/instagram.svg'} alt='Instagram' className='InstagramContact' />
                      </div>
                      <p>Retrouvez-nous sur <a href="https://www.instagram.com/flipmycar.fr/">Instagram</a></p>
                  </div>
              </div>

          </div></>
  );
}

export default Contact;

