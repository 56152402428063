import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./VehicleDetails.css";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import Contact from "./Contact";

function VehicleDetails({ vehicle, onClose }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };

  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    async function fetchImages() {
      const storage = getStorage();
      const imageFolderRef = ref(storage, `VOITURES/${vehicle.id}`);
      try {
        const images = await listAll(imageFolderRef);
        const urlPromises = images.items.map((imageRef) =>
          getDownloadURL(imageRef)
        );
        const urls = await Promise.all(urlPromises);

        setImageUrls(urls);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    }

    if (vehicle) {
      fetchImages();
    }
  }, [vehicle]);

  if (!vehicle) {
    // Handle the case where the vehicle is not defined
    return null; // or some default content
  }

  const getLivraisonText = () => {
    if (vehicle.livraison) {
      return (
        <div className="livraison">
          <img
            className="imglivraison"
            src="livraison.svg"
            alt="picto_livraison"
          />
          <p className="plivraison">Livraison</p>
        </div>
      );
    }
    return null;
  };
  const getGarantieText = () => {
    if (vehicle.garantieMois > 0) {
      return (
        <div className="garantie">
          <img
            className="imggarantie"
            src="garantie.svg"
            alt="picto_garantie"
          />
          <p className="pgarantie">{`Garantie ${vehicle.garantieMois} mois`}</p>
        </div>
      );
    }
    return null;
  };

  // Composants pour les flèches du slider
  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="slick-arrow slick-prev" onClick={onClick}>
        &lt; Précédent
      </div>
    );
  }
  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="slick-arrow slick-next" onClick={onClick}>
        Suivant &gt;
      </div>
    );
  }

  const date = vehicle.MiseEnCiruclation.toDate();
  var month = date.getMonth();
  const year = date.getFullYear();
  const monthNames = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];
  month = monthNames[month];

  return (
    <div className="ModalSup">
      <button className="bouton" onClick={onClose}>
        Retour
      </button>
      <div className="Modal">
        <Slider {...settings} className="DivSlider2">
          {imageUrls.map((url, index) => (
            <div key={index}>
              <img
                className="slider-image2"
                src={url}
                alt={`vehicle ${index + 1}`}
              />
            </div>
          ))}
        </Slider>
        <div className="vehicle-info">
          <h3 className="vehicle-title">{`${vehicle.marque} ${vehicle.modele}`}</h3>
          <p className="vehicle-detailsPrecis">{`${vehicle.modelePrecis}`}</p>
          <p className="vehicle-details ">&nbsp;&nbsp;{`${month} ${year}`}</p>
          <p className="vehicle-details ">&nbsp;&nbsp;{`${vehicle.kilometrage.toLocaleString()} km`}</p>
          <p className="vehicle-details ">&nbsp;&nbsp;{`${vehicle.carburant}`}</p>
          <p className="vehicle-details ">&nbsp;&nbsp;{`${vehicle.boiteVitesse}`}</p>
          <hr className="bar-separation" />
          <p className="vehicle-details loc2 ">{`${vehicle.description}`}</p>
          <hr className="bar-separation" />
          <p className="vehicle-price spaced">{`${vehicle.prix.toLocaleString()} €`}</p>
          <div className="livraisonGarantie">
            {getGarantieText()} {/* Nouveau composant pour la garantie */}
            {getLivraisonText()} {/* Nouveau composant pour la livraison */}
          </div>
          <hr className="bar-separation" />
          <p className="vehicle-localisation loc2">{`📍 ${vehicle.localisation}, ${vehicle.localisation_postale} `}</p>
          <hr className="bar-separation" />
          <div className="ColContacterData2">
            <div className="Coordonnees">
              <div className="OptionContac2">
                <p className="textContact">
                  Téléphone : <a href="tel:+33123456789">06 82 75 46 29</a>
                </p>
              </div>
              <div className="OptionContact2">
                <p className="textContact">
                  SMS au <a href="sms:+33123456789">06 82 75 46 29</a>
                </p>
              </div>
            </div>
            <div className="Coordonnees">
              <div className="OptionContact2">
                <p className="textContact">
                  Email :{" "}
                  <a href="mailto:contact@example.com" className="Mail">
                    contact@flipmycar.fr
                  </a>
                </p>
              </div>
              <div className="OptionContact2">
                <p className="textContact">
                  Retrouvez-nous sur{" "}
                  <a href="https://www.instagram.com/flipmycar.fr/">
                    Instagram
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleDetails;
