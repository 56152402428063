import React from 'react';

const PrivacyPolicy = () => (
    <div style={{ padding: '20px', color:' white', maxWidth: '800px', margin: 'auto' }}>
        {/* hide bullet points for ul */}
        <style>{`
            ul {
                list-style-type: none;
            }
        `}</style>

        <h1>Politique de Confidentialité</h1>

        <p>Bienvenue sur FLIP MY CAR. Nous accordons une grande importance à la confidentialité de vos informations personnelles. Cette politique de confidentialité décrit les types d'informations que nous collectons, comment nous les utilisons, et les mesures que nous prenons pour les protéger.</p>

        <h2>Informations Collectées</h2>
        <h3>Informations que Vous Nous Fournissez</h3>
        <p>Lorsque vous utilisez notre site, nous pouvons collecter les informations suivantes :</p>
        <ul>
            <li><strong>Données d'identification</strong> : nom, adresse e-mail, numéro de téléphone.</li>
            <li><strong>Données de transaction</strong> : informations sur les achats ou ventes effectués sur notre plateforme.</li>
            <li><strong>Données de localisation</strong> : adresse, code postal, ville, pays.</li>
            <li><strong>Contenu utilisateur</strong> : avis, commentaires, et autres communications effectuées sur notre site.</li>
        </ul>

        <h3>Informations Collectées Automatiquement</h3>
        <p>Lorsque vous visitez notre site, nous pouvons collecter automatiquement des informations telles que :</p>
        <ul>
            <li><strong>Données de navigation</strong> : adresse IP, type de navigateur, pages visitées, temps passé sur le site.</li>
            <li><strong>Cookies et technologies similaires</strong> : pour améliorer votre expérience utilisateur et collecter des statistiques sur l'utilisation de notre site.</li>
        </ul>

        <h2>Utilisation des Informations</h2>
        <p>Nous utilisons vos informations pour :</p>
        <ul></ul>
    <h2>Partage des Informations</h2>
    <p>Nous ne partageons pas vos informations personnelles avec des tiers, sauf dans les cas suivants :</p>
    <ul>
      <li><strong>Fournisseurs de services</strong> : nous pouvons partager vos informations avec des prestataires qui nous aident à fournir nos services (par exemple, services de paiement, hébergement de site).</li>
      <li><strong>Exigences légales</strong> : nous pouvons divulguer vos informations si cela est requis par la loi ou pour protéger nos droits, notre propriété ou notre sécurité.</li>
    </ul>

    <h2>Protection des Informations</h2>
    <p>Nous mettons en œuvre des mesures de sécurité techniques et organisationnelles pour protéger vos informations contre l'accès non autorisé, la divulgation, la modification ou la destruction.</p>

    <h2>Vos Droits</h2>
    <p>Vous avez le droit de :</p>
    <ul>
      <li>Accéder à vos informations personnelles.</li>
      <li>Demander la correction de vos informations.</li>
      <li>Demander la suppression de vos informations.</li>
      <li>Vous opposer à l'utilisation de vos informations à des fins de marketing.</li>
    </ul>
    <p>Pour exercer ces droits, vous pouvez nous contacter à <a href="mailto:contact@flipmycar.fr">contact@flipmycar.fr</a>.</p>

    <h2>Modifications de la Politique de Confidentialité</h2>
    <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page avec la date de la dernière mise à jour.</p>

    <h2>Contact</h2>
    <p>Pour toute question concernant cette politique de confidentialité, vous pouvez nous contacter à :</p>
    <p><strong>Adresse e-mail</strong> : <a href="mailto:contact@flipmycar.fr">contact@flipmycar.fr</a></p>
    <p><strong>Adresse postale</strong> : 9 rue des colonnes</p>
  </div>
);

export default PrivacyPolicy;
