// VotreFormulaire.js
import React, { useState } from "react";
import "./VotreFormulaire.css"; // Assurez-vous que le chemin est correct pour votre fichier CSS
import Contact from "./Contact";
import { Helmet } from "react-helmet";

function VotreFormulaire() {
  const [step, setStep] = useState(1);

  // Partie 1 - Modèle et mécanique
  const [marque, setMarque] = useState("");
  const [modele, setModele] = useState("");
  const [boiteVitesse, setBoiteVitesse] = useState("");
  const [carburant, setCarburant] = useState("");
  const [kilometrage, setKilometrage] = useState("");
  const [couleur, setCouleur] = useState("");
  const [options, setOptions] = useState("");
  const [budget, setBudget] = useState("");
  const [puissance, setPuissance] = useState("");
  const [year, setYear] = useState("");

  // Partie 3 - Bénéficiez-vous d'une reprise
  const [repriseMarque, setRepriseMarque] = useState("");
  const [repriseModele, setRepriseModele] = useState("");
  const [repriseKilometrage, setRepriseKilometrage] = useState("");
  const [repriseYear, setRepriseYear] = useState("");
  const [repriseImmat, setRepriseImmat] = useState("");

  const [isChecked, setIsChecked] = useState(false);

  const [nomError, setNomError] = useState(true);
  const [numeroTelephoneError, setNumeroTelephoneError] = useState(true);

  // Partie 4 - Nous vous rappelons
  const [nom, setNom] = useState("");
  const [numeroTelephone, setNumeroTelephone] = useState("");

  const validateForm = () => {
    let isValid = true;

    if (!nom) {
      setNomError(true);
      isValid = false;
    }

    if (!numeroTelephone) {
      setNumeroTelephoneError(true);
      isValid = false;
    }
    return isValid;
  };

  const nextStep = () => {
    window.scrollTo(0, 0);
    setStep(step + 1);
  };

  const prevStep = () => {
    // remonte en haut de la page
    window.scrollTo(0, 0);
    setStep(step - 1);
  };

  const sendForm = async () => {
    if (validateForm()) {
      const data = {
        formulaire: "RECHERCHE PERSO",
        marque: marque,
        modele: modele,
        boiteVitesse: boiteVitesse,
        carburant: carburant,
        kilometrage: kilometrage,
        couleur: couleur,
        options: options,
        repriseMarque: repriseMarque,
        repriseModele: repriseModele,
        repriseKilometrage: repriseKilometrage,
        repriseYear: repriseYear,
        repriseImmat: repriseImmat,
        nom: nom,
        year: year,
        numeroTelephone: numeroTelephone,
        budget: budget,
        puissance: puissance,
      };

      try {
        await fetch("https://flipmycar.fr/api/sendEmail", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
          credentials: "same-origin",
        });
      } catch (error) {
        console.error("Error:", error);
      }
      window.scrollTo(0, 0);
      setStep(4);
    }
    return null;
  };

  return (
    <div>
      <Helmet>
        <title>Recherche personnalisée - FLIP MY CAR</title>
        <meta
          name="description"
          content="Remplissez notre formulaire de recherche personnalisée pour trouver le véhicule de vos rêves. Indiquez vos critères de recherche et bénéficiez de l'expertise de notre équipe pour trouver le véhicule qui correspond parfaitement à vos besoins."
        />
        <meta
          name="keywords"
          content="formulaire de recherche de véhicule, recherche personnalisée, formulaire de contact, acheter voiture, recherche véhicule, critères de recherche, formulaire reprise véhicule"
        />
      </Helmet>

      <h1 className="form-title">Formulaire de contact</h1>
      <div className="formulaire-container">
        <here>
          {/* Partie 1 */}
          {step === 1 && (
            <div className="form-step">
              <h2>Étape 1 - Critères de recherche</h2>
              <br></br>
              <label className="labelmenuderoulant">
                <t className="label">Marque :</t>
                <input
                  type="text"
                  value={marque}
                  onChange={(e) => setMarque(e.target.value)}
                />
              </label>
              <label className="labelmenuderoulant">
                <t className="label"> Modèle :</t>
                <input
                  type="text"
                  value={modele}
                  onChange={(e) => setModele(e.target.value)}
                />
              </label>
              <label className="labelmenuderoulant">
                <t className="label"> Année minimum de mise en circulation :</t>
                <input
                  type="text"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                />
              </label>
              <label className="labelmenuderoulant">
                <t className="label">Transmission : </t>
                <select
                  value={boiteVitesse}
                  onChange={(e) => setBoiteVitesse(e.target.value)}
                  className="menudroulant"
                >
                  <option value="">Sélectionnez une boîte de vitesse</option>
                  <option value="Manuelle">Manuelle</option>
                  <option value="Automatique">Automatique</option>
                  {/* Ajoutez d'autres options au besoin */}
                </select>
              </label>
              <label className="labelmenuderoulant">
                <t className="label">Carburant :</t>
                <select
                  value={carburant}
                  onChange={(e) => setCarburant(e.target.value)}
                  className="menudroulant"
                >
                  <option value="">Sélectionnez un carburant</option>
                  <option value="Essence">Essence</option>
                  <option value="Hybride">Hybride</option>
                  <option value="Diesel">Diesel</option>
                  <option value="Electrique">Electrique</option>
                  {/* Ajoutez d'autres options au besoin */}
                </select>
              </label>
              <label className="labelmenuderoulant">
                <t className="label">Puissance en cv :</t>
                <input
                  type="text"
                  value={puissance}
                  onChange={(e) => setPuissance(e.target.value)}
                />
              </label>
              <label className="labelmenuderoulant">
                <t className="label"> Kilométrage Maximum : </t>
                <select
                  value={kilometrage}
                  onChange={(e) => setKilometrage(e.target.value)}
                  className="menudroulant"
                >
                  <option value="">Sélectionnez un kilométrage maximum</option>
                  <option value="Maximum 10 000 km">Maximum 10 000 km</option>
                  <option value="Maximum 25 000 km">Maximum 25 000 km</option>
                  <option value="Maximum 50 000 km">Maximum 50 000 km</option>
                  <option value="Maximum 75 000 km">Maximum 75 000 km</option>
                  <option value="Maximum 100 000 km">Maximum 100 000 km</option>
                  {/* Ajoutez d'autres options au besoin */}
                </select>
              </label>
              <label className="labelmenuderoulant">
                <t className="label"> Couleur exterieure :</t>
                <input
                  type="text"
                  value={couleur}
                  onChange={(e) => setCouleur(e.target.value)}
                />
              </label>
              <label className="labelmenuderoulant">
                <t className="label">Options :</t>
                <input
                  type="text"
                  value={options}
                  onChange={(e) => setOptions(e.target.value)}
                />
              </label>
              <label className="labelmenuderoulant">
                <t className="label">Budget :</t>
                <input
                  type="text"
                  value={budget}
                  onChange={(e) => setBudget(e.target.value)}
                />
              </label>
              <p style={{ color: "grey" }}>
                Tous les champs ne sont pas obligatoires
              </p>
              <button type="button" onClick={nextStep}>
                Suivant
              </button>
            </div>
          )}

          {/* Partie 2 */}
          {step === 2 && (
            // faire une case a cocher pour la reprise de l'ancien véhicule et si oui afficher la configuration de l'ancien véhicule si non masquer la partie configuration de l'ancien véhicule et afficher uniquement les champs de contact
            <div className="form-step">
              <h2>Étape 2 - Votre véhicule actuel</h2>

              <checkbox className="checkbox">
                <input
                  className="reprisecheckbox"
                  type="checkbox"
                  id="reprise"
                  name="reprise"
                  value="reprise"
                  onChange={(e) => setIsChecked(e.target.checked)}
                />
                <label for="reprise">
                  Je souhaite bénéficier d'une reprise de mon ancien véhicule{" "}
                </label>
              </checkbox>
              {isChecked && (
                <>
                  <label className="labelmenuderoulant">
                    <t className="label">Marque :</t>
                    <input
                      type="text"
                      value={repriseMarque}
                      onChange={(e) => setRepriseMarque(e.target.value)}
                    />
                  </label>
                  <label className="labelmenuderoulant">
                    <t className="label"> Modèle :</t>
                    <input
                      type="text"
                      value={repriseModele}
                      onChange={(e) => setRepriseModele(e.target.value)}
                    />
                  </label>
                  <label className="labelmenuderoulant">
                    <t className="label"> Kilométrage :</t>
                    <input
                      type="text"
                      value={repriseKilometrage}
                      onChange={(e) => setRepriseKilometrage(e.target.value)}
                    />
                  </label>
                  <label className="labelmenuderoulant">
                    <t className="label"> Année de mise en circulation :</t>
                    <input
                      type="text"
                      value={repriseYear}
                      onChange={(e) => setRepriseYear(e.target.value)}
                    />
                  </label>
                  <label className="labelmenuderoulant">
                    <t className="label"> Immatriculation :</t>
                    <input
                      type="text"
                      value={repriseImmat}
                      onChange={(e) => setRepriseImmat(e.target.value)}
                    />
                  </label>
                  <p style={{ color: "grey" }}>
                    Tous les champs ne sont pas obligatoires
                  </p>
                </>
              )}
              <br />
              <div className="selectpage">
                <button type="button" onClick={prevStep}>
                  Précédent
                </button>
                <button type="button" onClick={nextStep}>
                  Suivant
                </button>
              </div>
            </div>
          )}
          {/* Partie 3 */}
          {step === 3 && (
            <div className="form-step">
              <h2>Étape 3 - Nous vous rappelons</h2>
              <br></br>
              <label className="labelmenuderoulant">
                <t className="label">Nom :</t>
                <input
                  type="text"
                  value={nom}
                  onChange={(e) => setNom(e.target.value)}
                  required
                />
              </label>
              <label className="labelmenuderoulant">
                <t className="label">Numéro de téléphone :</t>
                <input
                  type="text"
                  value={numeroTelephone}
                  onChange={(e) => setNumeroTelephone(e.target.value)}
                  required // Add the required attribute
                />
              </label>
              <div className="selectpage">
                <button type="button" onClick={prevStep}>
                  Précédent
                </button>
                <button onClick={() => [sendForm()]}>Envoyer</button>
              </div>
            </div>
          )}
          {/* Partie 4 */}
          {step === 4 && (
            <div className="form-step">
              <h2>Confirmation</h2>
              <p>Votre formulaire a été transmis à notre équipe.</p>
            </div>
          )}
        </here>
      </div>
      <Contact />
      <br />
      <div className="bannieredubas">
        <img
          src={process.env.PUBLIC_URL + "/IMG_6557.jpeg"}
          alt="bannieredubas"
          className="bannieredubas"
        />
      </div>
    </div>
  );
}

export default VotreFormulaire;
