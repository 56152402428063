// AccueilContent.js
import { useState } from 'react';
import React from 'react';
import './AccueilContent.css';
import NosVehiculesContent from './NosVehiculesContent';
import Section from './Section.js';
import RepriseDepotsVenteContent from './RepriseDepotsVenteContent';
import Contact from './Contact.js';
import Slider from 'react-slick';
import { Helmet } from 'react-helmet';



function AccueilContent({changeSection}) {

  const imageUrls = [
    process.env.PUBLIC_URL + '/IMG_6536.jpeg',
    process.env.PUBLIC_URL + '/IMG_6556.png',
    process.env.PUBLIC_URL + '/IMG_6535.jpeg',
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    easing: 'ease-in-out',
    swipeToSlide: true,
    pauseOnHover: false,
    draggable: false,
  };
  // Composants pour les flèches du slider


  return (
    <div className='accueil'>
      <Helmet>
        <title>FLIP MY CAR - Vendez et trouvez votre voiture rapidement et facilement</title>
        <meta name="description" content="FLIP MY CAR vous permet de vendre votre voiture plus rapidement et de trouver votre prochain véhicule facilement. Découvrez notre service complet de dépôt-vente, recherche personnalisée, et véhicules disponibles." />
        <meta name="keywords" content="vendre voiture, acheter voiture, dépôt vente, véhicules d'occasion, recherche personnalisée, reprise voiture" />
      </Helmet>
      <h2 className='banniere'> Vous souhaitez changer de voiture ? Avec <strong><em><span className="smaller-text">Flip my car</span></em></strong>, vendez mieux et trouvez la suivante </h2>
      <h2 className='banniereMobile'> Vous souhaitez changer de voiture ?</h2>
      <h2 className='banniereMobile2'>Avec <strong><em><span className="smaller-text">Flip my car</span></em></strong>, vendez mieux et trouvez la suivante</h2>
      <Slider {...settings} className='SliderBanniere' >
        {imageUrls.map((url, index) => (
          <div key={index} >
            <img className="slider-image" src={url} alt={`vehicle ${index + 1}`} />
          </div>
        ))}
      </Slider>
      {/* Ajoutez ici le contenu spécifique à la section Accueil */}
      <div className='formules'>
        <div onClick={() => changeSection('Reprise/Dépôts vente')} className='reprise'>
          <h1 className='titreforMobile'>Reprise et dépôt-vente</h1>
          <img src={process.env.PUBLIC_URL + '/reprise.png'} alt='reprise' className='logo'/>
          <h3 className='detail'>Nous nous chargeons de la vente de votre voiture au meilleur prix</h3>
          <button onClick={() => changeSection('Reprise/Dépôts vente')}  className='bouton'>Accès au formulaire</button>
        </div>
       <div onClick={() => changeSection('Nos véhicules')} className='dispo'>
          <h1 className='titreforMobile'>Véhicules disponibles</h1>
          <img src={process.env.PUBLIC_URL + '/vdispo.png'} alt='reprise' className='logo'/>
          <h3 className='detail'>Consultez notre selection de véhicules disponibles</h3>
          <button onClick={() => changeSection('Nos véhicules')} className='bouton'>Voir les véhicules</button>
          </div>
       <div onClick={() => changeSection('Recherches personnalisées')} className='recherche'>
          <h1 className='titreforMobile'>Recherche personnalisée</h1>
          <img src={process.env.PUBLIC_URL + '/search.png'} alt='reprise' className='logo'/>
          <h3 className='detail'>Nous trouverons selon vos critères votre prochaine voiture</h3>
          <button onClick={() => changeSection('Recherches personnalisées')}  className='bouton'>Accès au formulaire</button>
       </div>

       <div onClick={() => changeSection('Reprise/Dépôts vente')} className='repriseMobile'>
          <div className='repriseMobileTitre'>
            <h1 className='titreforMobile'>Reprise et dépôt-vente</h1>
            <img src={process.env.PUBLIC_URL + '/reprise.png'} alt='reprise' className='logoMobile'/>
          </div>
          <h3 className='detail'>Vendez votre voiture au meilleur prix</h3>
          <button onClick={() => changeSection('Reprise/Dépôts vente')}  className='bouton'>Accès au formulaire</button>
        </div>
       <div onClick={() => changeSection('Nos véhicules')} className='dispoMobile'>
       <div className='repriseMobileTitre'>
          <h1 className='titreforMobile'>Véhicules disponibles</h1>
          <img src={process.env.PUBLIC_URL + '/vdispo.png'} alt='reprise' className='logoMobile'/>
          </div>
          <h3 className='detail'>Notre sélection de véhicules disponibles</h3>
          <button onClick={() => changeSection('Nos véhicules')} className='bouton'>Voir les véhicules</button>
          </div>
       <div onClick={() => changeSection('Recherches personnalisées')} className='rechercheMobile'>
       <div className='repriseMobileTitre'>
          <h1 className='titreforMobile'>Recherche personnalisée</h1>
          <img src={process.env.PUBLIC_URL + '/searchmobile.png'} alt='reprise' className='logoMobile'/>
          </div>
          <h3 className='detail'>Nous trouverons votre prochaine voiture</h3>
          <button onClick={() => changeSection('Recherches personnalisées')}  className='bouton'>Accès au formulaire</button>
       </div>
      </div>
      <div className='complet'>
        <h1 className='titreforComplet'>Le service complet</h1>
        <img src={process.env.PUBLIC_URL + '/logo.svg'} alt='reprise' className='logoComplet'/>
      </div>
      <div className='completeDiv'>
      <div className='formz'>
        <h1 className='completeDivTitre' >Dépot-vente</h1>
        <h1 className='completeDivTitre ou' >ou</h1>
        <h1 className='completeDivTitre' >Reprise</h1>
        </div>
        <img src={process.env.PUBLIC_URL + '/plus.svg'} alt='reprise' className='logoCompleteDiv'/>
        <div className='formz'>
        <h1 className='completeDivTitre' >Recherche personnalisée </h1>
        <h1 className='completeDivTitre ou' >ou </h1>
        <h1 className='completeDivTitre' >Véhicule disponible</h1>
        </div>
        
      </div>
      <div className='vehiculeDispo'>
        <NosVehiculesContent />
      </div>    
      <div className='complet'>
        <h1 className='titreforComplet'>Nos points forts</h1>
        </div>
        <div className='pointfor'>
          <div className='point'>
            <h3 className='detailpoint'>Service complet</h3>
              <p className='pointforDesc'>Dépôt-vente ou reprise, véhicules disponibles ou recherche personnalisée, notre service complet répond à tous vos besoins automobiles.</p>
          </div>
          <div className='point'>
            <h3 className='detailpoint'>Garantie premium</h3>
              <p className='pointforDesc'>Tous nos véhicules bénéficient d'une garantie premium de 6 mois. Une extension jusqu'à 24 mois est possible pour une tranquillité prolongée.</p>
          </div>
          <div className='point'>
            <h3 className='detailpoint'>Contrôle qualité</h3> 
              <p className='pointforDesc'>
              Nos véhicules sont soumis à un examen approfondi, assurant une qualité optimale et votre entière satisfaction.
              </p>
          </div>
        </div>
        <div className='pointfor'>
          <div className='point'>
            <h3 className='detailpoint'>Livraison</h3>
              <p className='pointforDesc'>Simplifiez votre achat avec notre service de livraison à domicile, partout en France.</p>
          </div>
          <div className='point'>
            <h3 className='detailpoint'>Prix compétitifs</h3>
              <p className='pointforDesc'>Nous vous proposons des véhicules de qualité à des prix compétitifs.</p>
          </div>
          <div className='point'>
            <h3 className='detailpoint'>Services client</h3>
              <p className='pointforDesc'>Notre équipe est là pour vous. Accompagnement, démarches simplifiées, service après-vente assuré. </p>
          </div>
        </div>
        <Contact/>
    <div className='complet'>
        <h1 className='titreforComplet'>Notre engagement</h1>
        </div> 
        <div className="ColFooterEngagement">
          <div className="ColFooterEngagementDesc"> 
            <h3>Nous nous engageons à vous offrir une expérience automobile de qualité. Avec des véhicules expertisés, un service client dédié, et des garanties étendues, nous mettons tout en œuvre pour vous assurer confiance et satisfaction à chaque étape de votre parcours avec nous.</h3>
            <br></br>
            <h3>Chez Flip my car, votre satisfaction est notre priorité.</h3>
          </div>
          <div>
            <img src={process.env.PUBLIC_URL + '/megane4.png'} className="Megane4" alt='Megane4'/>
          </div>
    </div>
  </div>
  );
}

export default AccueilContent;
